import "../styles/bootstrap.min.css";
import "../styles/globals.css";
import "../styles/inputStyles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { animateScroll as scroll } from "react-scroll";
import type { AppProps } from "next/app";
import { ReactElement, useEffect } from "react";
import { Router } from "next/router";
import React from "react";
import WhatsAppBtn from "../components/WhatsAppBtn/WhatsAppBtn";

function MyApp({ Component, pageProps }: AppProps) {
  const [isLoaded, setIsLoaded] = React.useState(false);

  useEffect(() => {
    const smoothScroll = () => {
      setIsLoaded(false);
      scroll.scrollToTop();
    };

    const handleComplete = () => {
      setIsLoaded(true);
    };

    setIsLoaded(true);
    Router.events.on("routeChangeStart", smoothScroll);
    Router.events.on("routeChangeComplete", handleComplete);

    return () => {
      Router.events.off("routeChangeStart", smoothScroll);
      Router.events.off("routeChangeComplete", handleComplete);
    };
  }, []);
  return (
    <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-D9DJ3PW92S"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `  window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-D9DJ3PW92S');`,
        }}
      ></script>
      <WhatsAppBtn isFixed />
      <Component {...pageProps} />
    </>
  );
}
export default MyApp;
