import React from "react";

import styles from "./WhatsAppBtn.module.css";
import cx from "classnames";
import { Img } from "../elements/Img";

interface IWhatsAppBtnProps {
  isFixed?: boolean;
}

const WhatsAppBtn = ({ isFixed = false }: IWhatsAppBtnProps) => {
  return (
    <div className={cx(styles.whatsAppBtn, { [styles.isFixed]: isFixed })}>
      <a href="https://wa.me/436645485103">
        <Img src={require("./assets/WUBig.svg")} />
      </a>
    </div>
  );
};

export default WhatsAppBtn;
