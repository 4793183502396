// @ts-nocheck
import React, { ReactElement, useEffect, useRef, useState } from "react";

export default function Img({
  src,
  noLazyLoad = false,
  ...props
}: any): ReactElement {
  let newSrc = src;

  const domRef = useRef();

  const [isVisible, setVisible] = useState(noLazyLoad);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // In your case there's only one element to observe:
        if (entries[0].isIntersecting) {
          // Not possible to set it back to false like this:
          setVisible(true);

          // No need to keep observing:
          observer.disconnect(domRef.current);
        }
      },
      { rootMargin: "150px" }
    );

    observer.observe(domRef.current);

    return () => observer.disconnect(domRef.current);
  }, []);

  return (
    <>
      <img ref={domRef} src={isVisible ? newSrc : ""} {...props} />
    </>
  );
}
